<template>
  <!-- Table Container Card -->
  <b-card no-body class="p-2">
    <div>
      employment in FRP are not considered as authentic and are only maintained
      by the user alone.
    </div>
    <b-row class="p-2">
      <b-col
        cols="12"
        sm="12"
        md="3"
        class="mt-1 d-flex align-items-center justify-content-center justify-content-sm-start"
        v-for="(item, index) in appData"
        :key="'documentlist' + index"
      >
        <router-link :to="item.namex" class="btn text-left btn-outline-primary w-100">
          <b-media vertical-align="left">
            <template #aside>
              <b-img
                :src="require('@/assets/images/icons/folder.png')"
                blank-color="#ccc"
                width="64"
                alt="placeholder"
              />
            </template>
            <h5 class="mt-1">{{ $t(item.title) }}</h5>
            {{ item.titlex in doccount ? doccount[item.titlex] : 0 }}
          </b-media>
        </router-link>
      </b-col>
    </b-row>
  </b-card>
</template>
  <script>
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormInput,
  VBTooltip,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BImg,
  BMediaAside,
} from "bootstrap-vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import StoreModule from "./StoreModule";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
export default {
  data() {
    return {};
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCardHeader,
    AppCollapse,
    AppCollapseItem,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMediaAside,
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    VBTooltip,
    BButton,
    BAvatar,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BImg,
    BPagination,
    vSelect,
  },
  setup() {
    const GENAPP_APP_STORE_MODULE_NAME = "/employmentvault";
    const modulename = "employment Vault";
    const modulenamesub = "/employmentvault";
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    const doccount = ref([]);
    store
      .dispatch(
        GENAPP_APP_STORE_MODULE_NAME + "/fetchedocumentcountmodule",
        "employment"
      )
      .then((response) => {
        doccount.value = response.data;
      });
    const appData = [
      {
        title: "navigation.salaryslips",
        titlex: "employment_salaryslips",
        namex: "/employment/salaryslips",
      },
      {
        title: "navigation.offerletters",
        titlex: "employment_offerletters",
        namex: "/employment/offerletters",
      },
      {
        title: "navigation.relievingletters",
        titlex: "employment_relievingletters",
        namex: "/employment/relievingletters",
      },
      {
        title: "navigation.form",
        titlex: "employment_form",
        namex: "/employment/form",
      },
      {
        title: "navigation.otherdocuments",
        titlex: "employment_otherdocuments",
        namex: "/employment/otherdocuments",
      },



    ];
    return {
      modulename,
      modulenamesub,
      appData,
      GENAPP_APP_STORE_MODULE_NAME,
      doccount,
    };
  },

  methods: {},
};
</script>

  <style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.electricity-filter-select {
  min-width: 190px;
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }
  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
